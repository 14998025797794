.modalWindowHalf {
    max-width: calc(50%);
    max-height: calc(50%);
}
.modalHeader {
    background: #f5ebb7;
}
.modalBody {
    text-align: center;
    background: darkgrey;
    color: black;
}
