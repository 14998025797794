.titleNavLineBlock {
    clear: both;
    color: #4f5050;
    font-size: large;
    font-weight: bold;
    padding-bottom: 20px;
}
.titleNavLineItem {
    float: left;
    padding: 0 5px 0 5px;
}
.titleNavLineItem:hover {
    cursor: pointer;
}
.titleNavLineSeperator {
    float: left;
    padding: 0 5px 0 5px;
    content:url("/src/client/img/breadcrumb_arrowRight_24.png");
}
.titleNavLineOptionBlock {
    float: right;
    margin-left: 50px;
    text-align: right;
}
.titleNavLineOptionItem {
    float: left;
    background-color: #e9e8e6;
    padding: 3px 10px 3px 10px;
    font-size: 14px;
    margin: 0 10px 0 10px;
}
.titleNavLineOptionItem:hover {
    cursor: pointer;
}
.titleNavLineOptionItemActive {
    float: left;
    background-color: #f5ebb7;
    padding: 3px 10px 3px 10px;
    font-size: 14px;
    margin: 0 10px 0 10px;
}
.titleNavLineOptionItemActive:hover {
    cursor: pointer;
}
.divApp {

}

