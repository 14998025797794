.appHeader {
    background-color: white;
    width: 100%;
    padding: 5px 5px 10px 5px;
    color: #4d5154;
    text-align: left;
}
.pageHeaderTable {
    width: 100%;
}
.pageHeaderTable tr:last-child {
    border-bottom: #cccccc solid 2px;
}
.navTable {
}
.navTable td {
    padding: 10px;
}
.navTableTdSelected {
    font-weight: bold;
    color: black;
    border-bottom: #bb2d3b solid 5px;
}
.navTableTdUnselected {
    font-weight: bold;
    color: #5c636a;
}
.navTableTdUnselected:hover {
    font-weight: bold;
    color: #5c636a;
    background: #e9e8e6;
}

a:link {
    text-decoration: none;
    color: inherit;
}
a:visited {
    text-decoration: none;
    color: inherit;
}
a:hover {
    text-decoration: none;
    color: inherit;
}
a:active {
    text-decoration: none;
    color: inherit;
}

.navTable tr:last-child {
    border-bottom: none;
}
.pageHeaderTable td {
    padding: 5px 20px 5px 20px;
}
.appTitle {
    color: #4d5154;
    font-size: xx-large;
    font-family: Broadway, cursive;
}
.AppContentDiv {
    margin: 20px 20px 5px 20px;
    width: calc(100% - 45px);
}
.flexRow {
    display: flex;
    flex-direction: row;
    float: right;
}

