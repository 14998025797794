.tableInfoBlock {
    width: 100%;
}
.divInfoBlock {
    width: 100%;
    display: table;
}
.divInfoBlockRow {
    display: table-row;
}
.divInfoBlockSpaceBlockWhite {
    display: table-cell;
    background-color: transparent;
    alignment: center;
    vertical-align: top;
    margin: 10px;
}
.divInfoBlockYellowTd {
    display: table-cell;
    background-color: cornsilk;
    padding: 20px;
    vertical-align: top;
    border: #f5ebb7 solid 2px;
    margin: 10px;
    border-radius: 20px;
}
.divInfoBlockWhiteSmokeTd {
    display: table-cell;
    background-color: whitesmoke;
    padding: 20px;
    vertical-align: top;
    border: #e9e8e6 solid 2px;
    margin: 10px;
    border-radius: 20px;
}
.divInfoBlockLightBlueTd {
    display: table-cell;
    background: #cff4fc;
    padding: 20px;
    border: #0dcaf0 solid 2px;
    vertical-align: top;
    margin: 10px;
    border-radius: 20px;
}

.divInfoBlockYellowTdGlow {
    display: table-cell;
    background-color: cornsilk;
    padding: 20px;
    vertical-align: top;
    border: #f5ebb7 solid 2px;
    margin: 10px;
    box-shadow: 0 0 18px 0 #fcd50a;
    border-radius: 20px;
}
.divInfoBlockWhiteSmokeTdGlow {
    display: table-cell;
    background-color: whitesmoke;
    padding: 20px;
    vertical-align: top;
    border: #e9e8e6 solid 2px;
    margin: 10px;
    box-shadow: 0 0 18px 0 #6B6A69FF;
    border-radius: 20px;
}
.divInfoBlockLightBlueTdGlow {
    display: table-cell;
    background: #cff4fc;
    padding: 20px;
    border: #0dcaf0 solid 2px;
    vertical-align: top;
    margin: 10px;
    box-shadow: 0 0 18px 0 #1c87c9;
    border-radius: 20px;
}
