.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.sliderDiv {
    float:left;
    padding: 0 20px 0 0;
    font-size: 14px;
    vertical-align: middle;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slider {
    background-color: goldenrod;
}
input:focus + .slider {
    box-shadow: 0 0 1px goldenrod;
}
input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}
.InputTextDiv {
    background-color: #61dafb;
}
.InputTextInputView {
    background-color: #fcf8f2;
    padding: 5px 8px 5px 8px;
    border: #fae5c5 solid 1px;
    box-shadow: 0 0 1px goldenrod;
}
.InputTextInputEdit {
    background-color: #FFFFFF;
    padding: 5px 8px 5px 8px;
    border: goldenrod solid 1px;
    box-shadow: 0 0 1px goldenrod;
}
