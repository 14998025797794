.divTable100 {
    text-align: left;
    display: table;
    width: 100%;
    border-top: #a2a2a1 double 5px;
    border-bottom: #a2a2a1 double 5px;
}
.divFormTable100 {
    text-align: left;
    display: table;
    width: 100%;
    border-top: #a2a2a1 solid 2px;
    border-bottom: #a2a2a1 solid 2px;

}
.divTableNoWidth {
    text-align: left;
    display: table;
}
.divTableRow {
    display: table-row;
    border-bottom: #a2a2a1 solid 2px;
}
.divTableRow:hover {
    cursor: pointer;
    background-color: whitesmoke;
}
.divTableFormRow {
    display: table-row;
    border-bottom: #a2a2a1 solid 2px;
}
.divTableFormLabelCell {
    display: table-cell;
    padding: 3px 5px 3px 5px;
    vertical-align: top;
    font-weight: bold;
    background: whitesmoke;
    border-bottom: #a2a2a1 solid 2px;

}
.divTableHeaderCell {
    display: table-cell;
    padding: 3px 5px 3px 5px;
    vertical-align: top;
    font-weight: bold;
    background: whitesmoke;
    border-bottom: #a2a2a1 solid 2px;
}
.divTableCell {
    display: table-cell;
    padding: 3px 5px 3px 5px;
    vertical-align: top;
    border-bottom: #e9e8e6 solid 2px;
}

.divTableFormLabelCell {
    display: table-cell;
    padding: 3px 5px 3px 5px;
    vertical-align: top;
    font-weight: normal;
    background: white;
    border-bottom: #ebeaea solid 2px;

}
.divTableFormTextCell {
    display: table-cell;
    padding: 3px 5px 3px 5px;
    vertical-align: top;
    font-weight: bold;
    background: white;
    border-bottom: #ebeaea solid 2px;
}
.divTableFormHeaderCell {
    display: table-cell;
    padding: 3px 5px 3px 5px;
    vertical-align: top;
    font-weight: bold;
    background: whitesmoke;
    border-bottom: #ebeaea solid 2px;
    font-size: large;
}
.divSectionHeader {
    width: 100%;
    font-weight: bold;
    font-size: large;
    color: #767675;
}